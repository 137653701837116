<template>
    <div class="description" v-if="description">{{ description }}</div>

    <div v-else>
        <div class="w-full">
            <div :class="`${color} h-2.5 rounded-full dark:bg-gray-700 w-48 mb-4`"></div>
            <div :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5`"></div>
            <div :class="`${color} h-2 rounded-full dark:bg-gray-700 mb-2.5`"></div>
            <div :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5`"></div>
            <div :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
            <div :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[360px]`"></div>

            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5 mt-3`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
            <div v-if="extra" :class="`${color} h-2 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5`"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "bg-lo-purple-100",
        },
        description: {
            type: String,
            required: true,
        },
        extra: {
            type: Boolean,
            default: false,
        },
    },
    name: "Description",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
