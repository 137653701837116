<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 98 122.5"
        style="enable-background: new 0 0 98 98"
        xml:space="preserve"
    >
        <path
            d="M86.359581,19.269228l-7.6283951-7.6287184c-2.6607666-2.6617117-6.9919891-2.6617117-9.653389,0L19.8510361,60.8672752  l-0.11479,0.1147881c-0.1883545,0.1880379-0.335804,0.4128609-0.4337883,0.6601944L9.7813053,85.6902542  c-0.2860212,0.7223511-0.1157398,1.5455322,0.4337883,2.0947418c0.3722725,0.3725891,0.8704319,0.5707779,1.3777866,0.5707779  c0.2409935,0,0.4842072-0.0447083,0.7169561-0.1369858l24.0483131-9.5211563  c0.2473373-0.0979767,0.4721603-0.24543,0.6601982-0.4337845l0.1144714-0.1144714l0,0l42.3268127-42.3270912  c0.000473-0.0004768,0.00103-0.0007553,0.0015106-0.0012283c0.000473-0.0004768,0.0007477-0.0010338,0.0012283-0.0015068  l6.8972092-6.8972569C89.0209808,26.2608986,89.0209808,21.9306221,86.359581,19.269228z M36.4360771,73.3359222  c-1.2296219-2.5360641-2.8682213-4.8400116-4.9000187-6.8715668c-2.0317593-2.0319977-4.3356667-3.6708374-6.8715706-4.9003792  l38.892334-38.8925781l11.7717514,11.772028L36.4360771,73.3359222z M22.2654514,64.7428741  c2.4235725,1.069252,4.6092815,2.570343,6.5156689,4.4764175c1.9060707,1.9060669,3.4068909,4.0918198,4.4761391,6.5153885  l-18.1959724,7.2041245L22.2654514,64.7428741z M83.6046448,26.1673546l-5.5211334,5.5211334L66.3117599,19.9164619  l5.5209808-5.5210142c1.1434479-1.1421833,3.0013199-1.1415491,4.1435013,0l7.6284027,7.6287184  C84.7468262,23.1663494,84.7468262,25.0251713,83.6046448,26.1673546z"
        />
    </svg>
</template>

<script>
export default {
    name: "Pencil",
};
</script>
