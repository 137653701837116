<template>
    <main-nav />
    <div class="min-h-screen" v-if="!isLoading">
        <!-- bread crumbs -->
        <div class="container flex items-center gap-2 mb-9 my-9">
            <router-link to="/me" class="text-lo-red-400">Spots</router-link>
            <span class="text-lo-red-400">/</span>
            <span v-if="spotID" class="text-lo-red-400">Edit</span>
            <span v-else class="text-lo-red-400">Add</span>
        </div>
        <edit-create-spot-view
            @locationSelected="locationSelected"
            @deleteImage="deleteImage"
            @imagesSelected="handleImages"
            :spot="spot"
            :isLoading="isLoading"
            :isSending="isSending"
            :class="editClassNames"
            class="mb-[200px]"
            @save="submit"
            @togglePreview="preview = !preview"
        />

        <!-- <spot :spot="spot" class="ml-[40%]" :class="{ hidden: !preview }" /> -->
        <!-- <div class="fixed top-0 right-0 bg-white">
            <pre>
                {{ spot }}
            </pre>
        </div> -->
    </div>
    <div v-if="isLoading" class="h-screen flex items-center justify-center w-full">
        <loader />
    </div>
</template>

<script>
import EditCreateSpotView from "./EditCreateSpotView.vue";
import Spot from "./Spot";
import store from "@/store";
export default {
    name: "EditSpot",
    components: {
        EditCreateSpotView,
        Spot,
    },
    data() {
        return {
            preview: false,
            isSending: false,
            isLoading: false,
            place: null,
            files: [],
            spot: {
                name: "",
                status: "Draft",
                type: "",
                description: "",
                place_id: "",
                address: "",
                phone: "",
                website: "",
                location: {
                    coordinates: [
                        -122.4194, // lng
                        37.7749, // lat
                    ],
                },
                categories: store.state.categories.map((category) => {
                    return {
                        _id: category._id,
                        name: category.name,
                        selected: false,
                    };
                }),
                tags: [],
                photos: [],
                visits: [],
                user: null,
            },
            error: null,
        };
    },
    methods: {
        async deleteImage(index) {
            let id = this.spot.photos[index]._id;

            let resp = await this.$api("spots/" + this.spotID + "/photos/" + id, {
                method: "DELETE",
                token: this.$token,
            });

            let info = await resp.json();
            if (resp.ok) {
                this.spot.photos.splice(index, 1);
            }

            console.log("Delete image: ", info);
        },

        submit() {
            if (this.spotID) this.updateSpot();
            else this.createSpot();
        },
        async locationSelected(place) {
            console.log("Location selected: ", place);

            this.place = place;

            this.spot.address = place.formatted_address;
            this.spot.place_id = place.place_id;
            this.spot.phone = place.formatted_phone_number;
            this.spot.website = place.website;
            this.spot.location = {
                type: "Point",
                coordinates: [place.geometry.location.lng(), place.geometry.location.lat()],
            };

            if (this.spot.name === "") this.spot.name = place.name;
        },
        async deleteSpot() {
            if (confirm("Are you sure?!")) {
                // await fetch(this.$api("spots/" + this.spotID), {
                //     method: "DELETE",
                // });
                this.$router.push({ path: "/spots" });
            }
        },
        async createSpot() {
            let spot = { ...this.spot };
            const token = await this.$auth.getAccessToken();

            spot.categories = spot.categories.filter((cat) => cat.selected).map((cat) => cat._id);

            let formData = new FormData();
            this.files.forEach((file, index) => {
                formData.append("files", file);
            });

            formData.append("spot", JSON.stringify(spot));

            try {
                let response = await this.$api("spots", {
                    method: "POST",
                    body: formData,
                    token,
                });

                let data = await response.json();
                if (response.status !== 200) {
                    this.error = data.message;
                    return null;
                }

                this.spotID = data._id;
                this.$router.push({ path: "spots/edit/" + data._id });

                return data;
            } catch (error) {
                console.log(error);
            }
        },
        async updateSpot() {
            this.isSending = true;
            let spot = { ...this.spot };
            spot.categories = spot.categories.map((cat) => cat._id);

            let formData = new FormData();
            this.files.forEach((file, index) => {
                formData.append("files", file);
            });

            formData.append("spot", JSON.stringify(spot));
            formData.append("location", JSON.stringify(this.place));

            let response = await this.$api("/spots/" + this.spotID, {
                method: "PUT",
                token: this.$token,
                body: formData,
            });

            if (!response.ok) {
                let data = await response.json();
                this.error = data.error;
                this.isSending = false;
                return null;
            }

            let data = await response.json();

            // let data = await response.json();
            // console.log(response.status);
            // if (response.status !== 200) {
            //     this.error = data.message;
            //     return null;
            // }

            setTimeout(() => {
                this.isSending = false;
                this.files = [];
            }, 250);
            // return data;
        },
        handleImages(files) {
            console.log("Files: ", files);
            this.files = files;
        },
        async fetchData(id) {
            const token = await this.$auth.getAccessToken();
            if (!id) {
                console.log("No ID provided");
                this.isLoading = false;
                return;
            }

            this.isLoading = true;
            try {
                let res = await this.$api("spots/" + id + "/edit", { token });
                let data = await res.json();

                console.log("Spot: ", data);

                if (res.ok) {
                    this.spot = data;

                    this.spot.categories = store.state.categories.map((category) => {
                        let selected = data.categories.find((cat) => cat._id === category._id);
                        return {
                            _id: category._id,
                            name: category.name,
                            selected: selected ? true : false,
                        };
                    });
                } else {
                    this.error = data.error;
                }

                this.isLoading = false;
            } catch (error) {
                console.log(error);
            }
        },
    },
    computed: {
        editClassNames() {
            if (this.preview) {
                return "p-4 max-w-[40%] shadow-lg fixed z-10 top-[70px] left-0 bg-lo-tan max-h-screen overflow-y-scroll";
            } else {
                return "container";
            }
        },
        spotID() {
            return this.$route.params.id;
        },
    },

    async mounted() {
        console.log(this.$route.params.id);
        if (this.$route.params.id) {
            this.fetchData(this.$route.params.id);
        }

        this.$nextTick(() => {
            if (this.$route.hash) {
                let el = document.getElementById(this.$route.hash.split("#")[1]);
                if (el) el.scrollIntoView();
            }
        });

        this.isLoading = false;
    },
};
</script>

<style scoped lang="scss"></style>
