<template>
    <div :class="`${deleted ? 'bg-red-300' : 'bg-white'} flex items-center  shadow-md rounded-lg p-4`">
        <img :src="item.thumbnail ? $image(item.thumbnail, 'c_fill,g_auto,h_500,w_500') : '/assets/missing.svg'" class="w-32 h-32 rounded-md mr-4" alt="Image" />

        <div class="flex-grow">
            <router-link :to="`/spots/${item.slug}`" class="text-xl font-bold">{{ item.name }}</router-link>
            <p class="text-gray-500">{{ $formatDate(item.created_at) }}</p>
            <p class="text-gray-500 font-bold">{{ status }}</p>
            <!-- {{ this.item.status }} -->
            <!-- <select class="mt-2 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                <option>Draft</option>
                                <option>Publish</option>
                                <option>Delete</option>
                            </select> -->
        </div>

        <div class="flex flex-col gap-2 ml-4 text-center">
            <router-link v-if="!deleted" :to="`/spots/${item._id}/edit`" class="bg-blue-500 active:bg-blue-600 button"> Edit </router-link>
            <router-link v-if="!deleted" :to="`/spots/${item._id}`" class="bg-blue-500 active:bg-blue-600 button"> View </router-link>
            <router-link to="" v-if="!deleted" class="bg-red-500 active:bg-red-600 button"> Delete </router-link>
            <router-link to="" v-if="deleted" class="bg-red-500 active:bg-red-600 button"> Restore </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        deleted: {
            type: Boolean,
            default: false,
        },
    },
    name: "ListItem",
    computed: {
        status() {
            if (this.item.status === "Publish") {
                return "Published";
            } else if (this.item.status === "Draft") {
                return "Draft";
            } else {
                return "Deleted";
            }
        },
    },
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.button {
    @apply text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150;
}
</style>
