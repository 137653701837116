import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import components from "./components";
import svgs from "./svgs";
import { oktaAuth, authInstall } from "./auth";
import { api, installAPI } from "./api";
import helpers from "./helpers";

import "./main.scss";

const app = createApp(App);

app.use(authInstall);
app.use(components);
app.use(svgs);
app.use(installAPI);
app.use(helpers);

// onAuth().then(async (resp) => {
//     let { user, token } = resp;

//     if (token) {
//         const response = await api("auth", { token, method: "POST" });
//         if (response.ok) {
//             let userData = await response.json();
//             user = userData;
//         } else {
//             console.error("Error fetching user data", response);
//         }
//     }

//     store.state.token = token;
//     store.state.user = user;

//     init();
// });

async function init() {
    const response = await api("categories");
    const categories = await response.json();
    store.commit("categories", categories);
    app.use(store);
    app.use(router);
    app.mount("#app");
}

init();
