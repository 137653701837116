<template>
    <main-nav />

    <div>
        <spots-map ref="spotsMap" :isLoading="isLoading" :fitToBounds="false" @boundsUpdated="boundsUpdated" :spots="spots" class="h-[60vh] max-h-[700px] w-full" />
        <div class="md:flex items-center justify-center hidden flex-wrap w-full gap-1 mt-9">
            <categories-list :categories="categories" :selected="selected" @click="toggleCategory" />
        </div>
    </div>

    <div class="mt-9 container min-h-screen">
        <div class="text-tiny pb-3">{{ spotsCountText }}</div>
        <div class="text-red-500 text-center p-9 italic" v-if="error">{{ error }}</div>
        <div class="columns-1 md:columns-2 lg:columns-3 xl:columns-4">
            <spot-tile v-for="spot in spots" :spot="spot" :key="spot.id" class="mb-6" />
        </div>
        <div class="p-6 text-center m-9">
            <a class="hover:underline cursor-pointer" @click="loadMore" v-if="!isLoadingMore">More</a>
            <span v-if="isLoadingMore">Loading...</span>
        </div>
    </div>

    <!-- scroll to top a with a arrow up font awesome -->
    <a
        @click="jumpToTop"
        ref="topButton"
        class="fixed bottom-6 cursor-pointer right-6 bg-lo-purple-200 hover:bg-lo-purple-100 w-[50px] h-[50px] flex items-center justify-center rounded-full text-black z-50"
    >
        <i class="fa-solid fa-arrow-up"></i>
    </a>
</template>

<script>
import store from "@/store";
import { getUserLocation } from "@/utils";
import { ErrorCodes } from "vue";
export default {
    name: "SpotsIndex",
    components: {},
    data() {
        return {
            isLoadingMore: false,
            isLoading: false,
            spots: [],
            total: 0,
            count: 0,
            nextPage: null,
            page: null,
            categories: {},
            error: "",
        };
    },
    computed: {
        selected() {
            let selected = [];
            for (let i in this.categories) {
                if (this.categories[i].selected) {
                    selected.push(this.categories[i]._id);
                }
            }
            return selected;
        },
        params() {
            let slugs = [];
            for (let i in this.categories) {
                if (this.categories[i].selected && this.categories[i].name !== "All") {
                    slugs.push(this.categories[i]._id);
                }
            }

            let params = {};

            if (slugs.length) {
                params.categories = slugs;
            }

            if (this.page) {
                params.page = this.page;
            }

            return params;
        },
        spotsCountText() {
            if (this.isLoading) {
                return "Loading...";
            }
            return `${this.spots.length} of ${this.total}`;
        },
    },
    methods: {
        jumpToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        loadMore() {
            this.page = this.nextPage;
            this.fetchData(this.params, true);
        },
        toggleCategory(e) {
            let cat = this.categories[e._id];

            if (cat) {
                cat.selected = !cat.selected;

                if (cat.name === "All") {
                    for (let i in this.categories) {
                        this.categories[i].selected = false;
                    }
                    cat.selected = true;
                } else {
                    this.categories[0].selected = false;
                }
            }

            // if nothing is selected, select all
            if (this.selected.length === 0) {
                this.categories[0].selected = true;
            }

            this.fetchData(this.params);
        },
        async boundsUpdated(bounds) {
            const q = {
                neLat: bounds.getNorthEast().lat,
                neLng: bounds.getNorthEast().lng,
                swLat: bounds.getSouthWest().lat,
                swLng: bounds.getSouthWest().lng,
            };
            this.fetchData({ ...q });
        },
        async fetchData(params, append = false) {
            this.isLoadingMore = true;
            if (this.isLoading) {
                return;
            }
            params = params || {};
            this.isLoading = true;
            let query = this.$queryString(params);
            let res = await this.$api("spots?" + query);
            let data = await res.json();

            if (!res.ok) {
                this.error = data.message;
                this.isLoading = false;
                return;
            }

            console.log(params);

            this.total = data.total;
            this.count = data.spots.length;

            if (append) {
                this.spots = this.spots.concat(data.spots);
            } else {
                this.spots = data.spots;
            }
            this.nextPage = data.nextPage;
            this.isLoading = false;
            this.isLoadingMore = false;
        },
        setMapLocation(location) {
            console.log("setMapLocation");
            let map = this.$refs.spotsMap.map;
            map.setCenter([location.longitude, location.latitude]);
            map.setZoom(3.8);

            // get map bounds
            let bounds = map.getBounds();
            let q = {
                neLat: bounds._ne.lat,
                neLng: bounds._ne.lng,
                swLat: bounds._sw.lat,
                swLng: bounds._sw.lng,
            };
            this.fetchData({ ...q, ...this.params });
        },
        updateScrollTopButton() {
            if (window.scrollY > 300) {
                this.$refs.topButton.style.display = "flex";
            } else {
                this.$refs.topButton.style.display = "none";
            }
        },
    },

    async mounted() {
        this.categories[0] = { _id: 0, name: "All", selected: true };
        for (let cat of store.state.categories) {
            this.categories[cat._id] = { ...cat, selected: false };
        }

        // do we have a cookie?
        let usersLocation = this.$cookies.get("usersLocation");
        if (usersLocation) {
            this.setMapLocation(usersLocation);
        } else {
            getUserLocation().then((location) => {
                this.$cookies.set("usersLocation", location, {
                    path: "/",
                    maxAge: 60 * 60 * 24 * 7,
                });
                this.setMapLocation(location);
            });
        }

        // infinite scroll
        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            if (bottomOfWindow && this.nextPage) {
                this.loadMore();
            }
            if (!this.$refs.topButton) return;

            this.updateScrollTopButton();
        };
        this.updateScrollTopButton();
    },
    unmounted() {
        window.onscroll = null;
    },
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         vm.fetchData(vm.params);
    //     });
    // },
    // beforeRouteUpdate(to, from, next) {
    //     this.fetchData(this.params);
    //     next();
    // },
};
</script>
