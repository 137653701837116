<template>
    <div @click="$emit('click', cat)" v-for="cat in categories" :key="cat._id" class="cat" :class="{ 'bg-lo-green-200': cat.selected, 'bg-lo-gray-100': true }">
        <div class="p-4 uppercase">
            {{ cat.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoriesList",
    props: {
        categories: {
            type: Object,
            required: true,
        },
        selected: {
            type: Array,
            default() {
                return [];
            },
        },
        computed: {},
    },
};
</script>

<style scoped lang="scss">
.cat {
    @apply flex items-center justify-center text-center text-xs min-w-[110px] min-h-[110px] w-[110px] h-[110px] hover:bg-lo-green-200 hover:text-white cursor-pointer transition-all;
}
</style>
