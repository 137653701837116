<template>
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="22px"
        viewBox="0 0 18 22"
        style="enable-background: new 0 0 18 22"
        xml:space="preserve"
    >
        <g id="Your_Icon_24_">
            <path
                style="fill: #311f28"
                d="M16.492,8.48C16.492,4.333,13.13,1,8.983,1S1.508,4.379,1.508,8.526
		c0,1.498,0.509,2.902,1.229,4.072v0.006l-0.026,0.011c0.043,0.065,0.071,0.135,0.117,0.2L8.991,21l6.186-8.211
		c0.03-0.045,0.058-0.146,0.087-0.191l0.033-0.113h-0.001C16.051,11.523,16.492,9.978,16.492,8.48z M8.983,10.041
		c-0.83,0-1.502-0.671-1.502-1.5c0-0.83,0.672-1.502,1.502-1.502c0.83,0,1.501,0.673,1.501,1.502S9.812,10.041,8.983,10.041z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "been-there",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
