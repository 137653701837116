<template>
    <div class="relative">
        <admin-map v-if="location" :location="location" class="h-96 w-full mb-6" />
        <div class="absolute top-3 left-0 w-full px-3">
            <input ref="autocompleteInput" type="text" placeholder="Search for a spots location" @keyup.enter.prevent="searchPlaces" class="w-full" />
        </div>
    </div>
</template>

<script>
import AdminMap from "@/admin/AdminMap.vue";
export default {
    name: "location-search",
    props: {
        location: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        searchPlaces() {
            console.log("Searching for places...");
        },
    },
    components: {
        AdminMap,
    },
    mounted() {
        const autocompleteInput = this.$refs.autocompleteInput;
        const autocomplete = new google.maps.places.Autocomplete(autocompleteInput);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            this.$emit("locationSelected", place);
        });
    },
    unmounted() {
        // google.maps.event.clearInstanceListeners(this.$refs.autocompleteInput);
    },
};
</script>
