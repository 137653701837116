<template>
    <main-nav />
    <spot :spot="spot" v-if="!isLoading" />
    <page-loader :loading="isLoading"> </page-loader>

    <main-footer />
</template>

<script>
import Spot from "@/views/spots/Spot.vue";
export default {
    name: "SpotView",
    components: {
        Spot,
    },
    mixins: [],
    data() {
        return {
            isLoading: true,
            spot: null,
            error: null,
        };
    },
    methods: {
        async fetchData(id) {
            this.isLoading = true;
            let res = await this.$api("spots/" + id);
            let data = await res.json();

            if (data.error) {
                this.error = data.error;
            } else {
                this.spot = data;
            }
            this.isLoading = false;
        },
    },
    async created() {
        await this.fetchData(this.$route.params.id);
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.fetchData(to.params.id);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to.params.id);
        next();
    },
};
</script>
