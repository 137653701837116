import { createStore } from "vuex";

export default createStore({
    state: {
        user: null,
        token: null,
        menuOpen: true,
        categories: [],
        imageApiUrl: process.env.VUE_APP_IMAGE_API_URL,
        apiUrl: process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "https://ideo-localsonly-0ba43ba7d5b9.herokuapp.com/",
        menu: {
            home: {
                title: "Spots",
                url: "/",
            },

            Research: {
                title: "Research",
                url: "/research",
            },
            itineraries: {
                title: "Itineraries",
                url: "/itineraries",
            },
            about: {
                title: "About",
                url: "/about",
            },
        },
    },
    getters: {},
    mutations: {
        toggleMenu(state) {
            state.menuOpen = !state.menuOpen;
        },
        closeMenu(state) {
            state.menuOpen = false;
        },
        categories(state, data) {
            state.categories = data;
        },
        logout(state) {
            state.user = null;
            state.token = null;
        },
        user(state, data) {
            state.user = data;
        },
        token(state, data) {
            state.token = data;
        },
    },
    actions: {},
    modules: {},
});
