import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";
import store from "./store";
import { api } from "./api";
// const oktaAuth = new OktaAuth({
//     issuer: `https://${process.env.VUE_APP_OKTA_DOMAIN}/oauth2/default`,
//     clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
//     redirectUri: window.location.origin + "/login/callback",
//     scopes: ["openid", "profile", "email"],
// });

// Okta Domain: ideo.okta.com
// Client ID: 0oa1vi5eobpMrr7b50h8
// Client Secret: DKSKOt6UCupTxPnj-jVydJIYJbvGz8AOPN1t2hlL-GhI8xWZfnemyv9iWCQgXFM9
// Sign-in redirect URI: https://localsonly.ideo.com/authorization-code/callback
// Sign-out redirect URI: https://localsonly.ideo.com

const oktaAuth = new OktaAuth({
    issuer: "https://ideo.okta.com",
    clientId: "0oa1vwiqo5r028aTU0h8",

    redirectUri: window.location.origin + "/authorization-code/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
});

oktaAuth.authStateManager.subscribe(async (authState) => {
    if (authState.isAuthenticated) {
        const userInfo = await oktaAuth.getUser();
        const accessToken = authState.accessToken;
        const idToken = authState.idToken;
        const token = oktaAuth.getAccessToken();
        let res = await api("auth", {
            token: token,
            method: "POST",
        });

        if (res.ok) {
            let data = await res.json();
            store.commit("user", data);
        }
        store.commit("token", accessToken);
    } else {
        store.commit("user", null);
        store.commit("token", null);
    }
});

const signIn = async () => {
    return oktaAuth.signInWithRedirect();
};

const authInstall = {
    install: (app) => {
        app.use(OktaVue, {
            oktaAuth,
            onAuthRequired: (oktaAuth) => {
                router.push({ path: "/login" });
            },
        });

        app.mixin({
            computed: {
                $token() {
                    return this.$store.state.token;
                },
                $userID() {
                    return this.$store.state.user ? this.$store.state.user._id : null;
                },
            },
        });
    },
};

export { oktaAuth, signIn, authInstall };
