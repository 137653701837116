import MainNav from "./MainNav";
import MainFooter from "./MainFooter";
import SpotsMap from "./SpotsMap";
import SpotMap from "./SpotMap";
import SpotCTA from "./SpotCTA";
import SpotTile from "./SpotTile";
import UserAvatar from "./UserAvatar";
import ItineraryTile from "./ItineraryTile";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import CategoriesList from "./CategoriesList";
import Tags from "./Tags";
import Loader from "./Loader";
import PageLoader from "./PageLoader";
import Description from "./Description";
import { createApp } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueMarkdown from "./Markdown";

import "swiper/css";
import "swiper/css/pagination";

const components = {
    Swiper,
    SwiperSlide,
    MainNav,
    MainFooter,
    SpotsMap,
    CategoriesList,
    Tags,
    PageLoader,
    Loader,
    "spot-cta": SpotCTA,
    "rich-editor": QuillEditor,
    SpotMap,
    SpotTile,
    ItineraryTile,
    UserAvatar,
    Description,
    markdown: VueMarkdown,
};

export default {
    install: (app) => {
        for (const component in components) {
            app.component(component, components[component]);
        }
    },
};
