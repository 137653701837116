import queryString from "query-string";
import VueCookies from "vue-cookies";

const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
};

// for now!@??!@
const isAdmin = (date) => {
    return store.state.user && store.state.user.email === "tvanderlin@ideo.com";
};

const timeSince = (ts) => {
    let date = new Date(ts).getTime() / 1000;
    var seconds = Math.floor(new Date().getTime() / 1000 - date),
        interval = Math.floor(seconds / 31536000);

    if (interval > 1) return interval + "y";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + "m";

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return interval + "d";

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return interval + "h";

    interval = Math.floor(seconds / 60);
    if (interval > 1) return interval + " m";

    return Math.floor(seconds) + "s";
};

const image = (asset, trans) => {
    const baseUrl = "https://res.cloudinary.com/dta8l58gz/image/upload/";
    const transformations = trans || "";
    const publicId = asset.public_id;
    const format = asset.format;
    return `${baseUrl}${transformations}/${publicId}.${format}`;
};

export default {
    install: (app) => {
        app.use(VueCookies);

        app.config.globalProperties.$queryString = (path) => {
            return queryString.stringify(path);
        };
        app.config.globalProperties.$formatDate = formatDate;
        app.config.globalProperties.$isAdmin = isAdmin;
        app.config.globalProperties.$image = image;
        app.config.globalProperties.$timeSince = timeSince;
    },
};
