import { createRouter, createWebHistory } from "vue-router";
import { LoginCallback } from "@okta/okta-vue";
import { navigationGuard } from "@okta/okta-vue";
import { oktaAuth } from "@/auth";

import UsersView from "../views/users/Single.vue";

import Itineraries from "../views/itineraries/Index.vue";
import ItineraryView from "../views/itineraries/Single.vue";

import SpotsView from "../views/spots/Index.vue";
import SpotView from "../views/spots/Single.vue";

import EditSpotView from "../views/spots/Edit.vue";

import ChatView from "../views/ChatView";

import AboutView from "../views/AboutView";
import ProfileView from "../views/users/ProfileView";
import store from "@/store";

const routes = [
    // auth
    {
        path: "/logout",
        component: SpotView,
        beforeEnter: async (to, from, next) => {
            store.commit("logout");
            await oktaAuth.signOut();
            next({ name: "home" });
        },
    },

    // {
    //     path: "/login/callback",
    //     component: LoginCallback,
    // },
    {
        path: "/authorization-code/callback",
        component: LoginCallback,
    },
    // admin
    {
        path: "/new-spot",
        name: "add-new-spot",
        component: EditSpotView,
    },
    {
        path: "/me",
        name: "profile",
        component: ProfileView,
        beforeEnter: (to, from, next) => {
            if (!store.state.user) {
                next({ name: "home" });
            } else {
                next();
            }
        },
    },
    {
        path: "/spots/:id/edit",
        name: "edit-spot",
        component: EditSpotView,
    },
    // other
    {
        path: "/",
        name: "home",
        component: SpotsView,
    },

    // about
    {
        path: "/about",
        name: "about",
        component: AboutView,
    },
    {
        path: "/research",
        name: "Research",
        component: ChatView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/itineraries",
        name: "Itineraries",
        component: Itineraries,
    },
    {
        path: "/spots/:id",
        name: "spots",
        component: SpotView,
    },

    {
        path: "/itineraries/:id",
        name: "Itinerary",
        component: ItineraryView,
    },
    {
        path: "/users/:id",
        name: "Users",
        component: UsersView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

// before you enter the route, close the menu
router.beforeEach(async (to, from, next) => {
    store.commit("closeMenu");
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = await oktaAuth.isAuthenticated();

    if (requiresAuth && !isAuthenticated) {
        try {
            oktaAuth.signInWithRedirect({ originalUri: to.fullPath });
        } catch (error) {
            console.log(error);
        }
    } else {
        next();
    }
});

export default router;
