<template>
    <div class="admin container" v-if="!isLoading">
        <div class="mx-auto mt-9">
            <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div class="mb-6 w-full">
                    <label for="name" class="">Name</label>
                    <input id="name" name="name" type="text" v-model="spot.name" placeholder="ie: IDEO Cambridge" class="" />
                </div>

                <div class="mb-6 flex-">
                    <label for="status" class="relative">
                        Status
                        <svg class="w-4 h-4 absolute right-[20px] top-[40px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M7 10l5 5 5-5z" />
                        </svg>
                    </label>
                    <select id="status" name="status" class="" v-model="spot.status">
                        <option value="Draft">Draft</option>
                        <option value="Publish">Published</option>
                    </select>

                    <!-- show a chevron -->
                </div>
            </div>

            <div>
                <!-- Location (Select) -->
                <div class="mb-9">
                    <label for="location" class="">Location</label>
                    {{ spot.place ? spot.place.place_id : "" }}
                    <location-search @locationSelected="locationSelected" :location="coordinates" />
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div class="mb-3">
                        <label for="type" class="">Address</label>
                        <input id="type" name="type" type="text" class="" v-model="spot.address" placeholder="ie: 123 Street" />
                    </div>
                    <div class="mb-3">
                        <label for="type" class="">Phone</label>
                        <input id="type" name="type" type="text" class="" v-model="spot.phone" placeholder="ie: 333-222-1234" />
                    </div>
                    <div class="mb-3">
                        <label for="type" class="">Website</label>
                        <input id="type" name="type" type="text" class="" v-model="spot.website" placeholder="ie: www.website.com" />
                    </div>

                    <!-- Type -->
                    <div class="mb-9">
                        <label for="type" class="">Type</label>
                        <input id="type" name="type" type="text" class="" v-model="spot.type" placeholder="ie: Bike Shop" />
                        <p class="text-gray-600 text-xs italic">e.g. Restaurant, Park, Museum, etc.</p>
                    </div>
                </div>

                <!-- Description -->
                <div class="mb-9" id="description">
                    <label for="description" class="">Description</label>
                    <rich-editor name="description" v-model:content="spot.description" contentType="html" class="w-full min-h-40 p-3" :options="textEditorOptions"></rich-editor>
                </div>

                <!-- Tags (Select multiple) -->
                <div class="mb-9">
                    <label for="tags" class="">Tags</label>
                    <tag-picker :tags="spot.tags" />
                </div>

                <!-- Categories (Select multiple) -->
                <div class="mb-9">
                    <categories-picker :categories="spot.categories" />
                </div>

                <!-- images -->
                <div class="flex gap-6 w-full" id="photos">
                    <image-uploader
                        @imagesSelected="
                            (e) => {
                                $emit('imagesSelected', e);
                            }
                        "
                        :files="spot.photos"
                        @deleteImage="
                            (e) => {
                                $emit('deleteImage', e);
                            }
                        "
                    />
                </div>
            </div>
        </div>

        <div class="mx-auto mt-9 fixed bottom-0 left-0 w-full bg-lo-purple-200">
            <div class="flex flex-wrap flex-row w-full justify-between items-end container h-full p-6">
                <div class="text-white">Updated: {{ $timeSince(spot.updated_at) }}</div>
                <div class="flex justify-end gap-4">
                    <form-button
                        :title="hasSpot ? 'Update' : 'Create'"
                        :disabled="isSending || !isValid"
                        :isLoading="isSending"
                        @click="
                            () => {
                                $emit('save');
                            }
                        "
                    />
                    <form-button
                        title="View Spot"
                        v-if="hasSpot"
                        @click="
                            () => {
                                $router.push(`/spots/${spot.slug}`);
                            }
                        "
                        class="bg-blue-300"
                    />

                    <!-- delete -->
                    <button
                        @click="deleteSpot"
                        v-if="spotID !== ''"
                        class="bg-lo-pink-200 hover:bg-lo-red-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed transition-all"
                    >
                        Delete
                    </button>
                </div>
            </div>

            <div v-if="error" class="py-9 text-center">
                <div class="error" v-html="error"></div>
            </div>
        </div>
    </div>
</template>

<script>
import TagPicker from "@/admin/TagPicker.vue";
import LocationSearch from "@/admin/LocationSearch.vue";
import CategoriesPicker from "@/admin/CategoriesPicker.vue";
import ImageUploader from "@/admin/ImageUploader.vue";
import FormButton from "@/admin/FormButton.vue";

export default {
    name: "AddSpotView",
    props: {
        spot: {
            type: Object,
            required: true,
        },
        isSending: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TagPicker,
        LocationSearch,
        CategoriesPicker,
        ImageUploader,
        FormButton,
    },
    mixins: [],
    data() {
        return {
            textEditorOptions: {
                placeholder: "",
                modules: {
                    toolbar: [["bold", "italic", "underline", "strike", "link"]],
                },
            },
            error: null,
            spotID: "",
            location: "",
        };
    },

    methods: {
        locationSelected(place) {
            this.$emit("locationSelected", place);
        },
    },
    computed: {
        isValid() {
            return this.spot && this.spot.name !== "" && this.spot.type !== "" && this.spot.description !== "" && this.spot.categories.length > 0;
        },
        coordinates() {
            if (this.spot) {
                return this.spot.location.coordinates;
            }
            return [-71.0589, 42.3601];
        },
        hasSpot() {
            return this.spot._id;
        },
    },
};
</script>

<style scoped lang="scss">
label {
    @apply text-tiny uppercase;
}
</style>
