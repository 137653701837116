<template>
    <main-nav />

    <template v-if="!isLoading">
        <div class="flex flex-wrap md:flex-nowrap">
            <div class="w-full lg:w-1/2 h-full overflow-auto">
                <div class="p-4">
                    <!-- details -->
                    <div class="w-full my-4">
                        <h1 class="text-3xl font-bold">{{ itinerary.title }}</h1>
                        <description class="text-xl italic max-w-[700px] py-9" :description="itinerary.description" :extra="true" />
                    </div>

                    <!-- map -->
                    <!-- <spots-map :spots="spots" class="h-96 w-full" /> -->

                    <!-- categories  -->
                    <div class="flex flex-wrap gap-1 my-9">
                        <categories :categories="categories" />
                    </div>

                    <div class="flex gap-5 flex-col my-9">
                        <h1 class="uppercase font-thin text-2xl">{{ itinerary.title }} Spots ({{ spots.length }})</h1>
                        <div v-for="(spot, index) in spots" :key="spot.id" class="bg-lo-gray-100 flex w-full md:h-[250px] md:flex-nowrap flex-wrap">
                            <div class="relative w-full max-h-60 md:max-h-max">
                                <div class="bg-lo-green w-11 h-11 uppercase flex items-center justify-center text-white absolute top-0 left-0">
                                    {{ index + 1 }}
                                </div>
                                <router-link :to="`/spots/${spot.slug}`" v-if="spot.thumbnail">
                                    <img :src="spot.thumbnail.url" alt="" class="object-cover w-full h-full" />
                                </router-link>
                            </div>

                            <div class="bg-lo-gray flex flex-col justify-around p-4 w-full">
                                <div>
                                    <router-link :to="`/spots/${spot.slug}`" class="font-sans uppercase text-2xl inline link">
                                        {{ spot.name }}
                                    </router-link>
                                </div>
                                <div class="text-sm font-serif">
                                    <div class="mb-6">
                                        {{ spot.address }}
                                    </div>
                                    <div>
                                        {{ spot.phone }}
                                    </div>
                                    <a target="_blank" class="font-bold link" :href="spot.website">{{ spot.website }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fixed inset-0 z-10 md:relative w-1/2 h-screen hidden lg:flex">
                <spots-map :spots="spots" class="h-screen w-full fixed top-0" />
            </div>
        </div>
    </template>

    <template v-else>
        <div class="container flex justify-center items-center h-[80vh]">
            <loader />
        </div>
    </template>

    <!-- <div class="container" v-if="!isLoading">
        <div class="mt-9">
            <div class="flex items-center justify-center">
                <div class="flex flex-col items-center flex-wrap">
                    <div class="text-center mt-4">
                        <h1 class="text-3xl font-bold">{{ itinerary.title }}</h1>
                        <div class="text-xl italic max-w-[700px] py-9">{{ itinerary.description }}</div>

                        <div class="flex flex-col items-center mb-9">
                            <div class="text-xs">created by</div>
                            <div class="flex">
                                <user-avatar :user="itinerary.user" :showName="false" />
                                <router-link :to="`/users/${itinerary.user.slug}`" class="text-lg font-bold ml-2 link"
                                    >{{ itinerary.user.firstname }} {{ itinerary.user.lastname }}</router-link
                                >
                            </div>
                            <div class="text-xs">{{ new Date(itinerary.created_at).toLocaleDateString() }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="min-h-screen container" v-if="!isLoading">
        <div class="flex justify-center gap-7 p-4 flex-wrap">
            <spot-tile v-for="spot in spots" :spot="spot" :key="spot.id" />
        </div>
    </div> -->
    <main-footer />
</template>

<script>
export default {
    name: "ItineraryView",
    components: {},
    mixins: [],
    data() {
        return {
            isLoading: true,
            itinerary: null,
        };
    },
    methods: {
        async fetchData(id) {
            this.isLoading = true;
            let res = await this.$api("itineraries/" + id);
            let data = await res.json();
            this.isLoading = false;
            this.itinerary = data;
        },
    },
    computed: {
        spots() {
            return this.itinerary.spots || [];
        },
        categories() {
            return this.itinerary.categories || [];
        },
    },
    async created() {
        await this.fetchData(this.$route.params.id);
    },
    mounted() {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.fetchData(to.params.id);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to.params.id);
        next();
    },
};
</script>

<style scoped lang="scss"></style>
