<template>
    <nav class="bg-lo-purple-300 p-4 z-20">
        <div class="flex items-center justify-between container">
            <router-link to="/" class="text-white"><img src="@/assets/logo.svg" alt="" /></router-link>

            <!--  menu -->
            <div class="flex justify-end items-center flex-row w-full">
                <!-- links desktop -->
                <div class="md:flex gap-4 justify-center items-center hidden">
                    <div v-for="link in $store.state.menu">
                        <router-link :to="link.url" class="text-white">{{ link.title }}</router-link>
                    </div>
                </div>

                <!-- user and add spot -->
                <div class="flex gap-2 mr-6 md:ml-4">
                    <button v-if="!$store.state.user" class="text-white hover:opacity-60" @click="login">Login</button>

                    <user-avatar v-if="$store.state.user" :user="$store.state.user" :showName="false" size="w-[25px]" path="/me" />

                    <router-link v-if="$store.state.user" to="/new-spot" class="text-white hover:opacity-60">
                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="27px"
                            height="27px"
                            viewBox="0 0 27 27"
                            style="enable-background: new 0 0 27 27"
                            xml:space="preserve"
                        >
                            <path
                                style="fill: #ab1f60"
                                d="M13.5,0C6.044,0,0,6.046,0,13.502C0,20.956,6.044,27,13.5,27C20.957,27,27,20.956,27,13.502
C27,6.046,20.957,0,13.5,0z M20.388,14.841h-5v5h-3v-5h-5v-3h5v-5h3v5h5V14.841z"
                            />
                        </svg>
                    </router-link>
                </div>

                <!-- mobile menu and burger -->
                <div class="md:hidden z-50">
                    <hamburger :show="$store.state.menuOpen" />
                    <div class="absolute top-[24px] right-0 h-full bg-lo-purple-400 z-10" :class="{ hidden: !menuOpen }">
                        <div class="flex flex-col items-end mt-11 p-9 justify-start bg-lo-purple-300">
                            <div v-for="link in $store.state.menu">
                                <router-link :to="link.url" class="text-white">{{ link.title }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end menu -->
        </div>
    </nav>
</template>

<script>
import Hamburger from "./Hamburger.vue";
import { signIn } from "../auth";
export default {
    name: "main-nav",
    components: {
        Hamburger,
    },
    mixins: [],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$store.commit("closeMenu");
        },
        async login() {
            return signIn();
        },
    },
    computed: {
        menuOpen() {
            return this.$store.state.menuOpen;
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
