<template>
    <main-nav />

    <div class="min-h-screen container" v-if="!isLoading">
        <div class="mt-9">
            <div class="flex items-center justify-center">
                <div class="flex flex-col items-center flex-wrap">
                    <img :src="user.photo.url" alt="item photo" class="w-28 h-28 mr-1 object-cover object-center bg-gray-400 rounded-full border-[5px] border-lo-purple" />
                    <div class="text-center mt-4">
                        <h1 class="text-2xl font-medium">{{ user.firstname }} {{ user.lastname }}</h1>
                        <p class="text-lg mb-2 capitalize" v-if="user.office">{{ user.office.slug }}</p>
                        <p class="text-tiny">Joined {{ new Date(user.created_at).toLocaleDateString() }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-9">
            <div class="flex flex-col max-w-[1400px] items-center mx-auto">
                <div class="flex w-full justify-center mb-6">
                    <a
                        @click.prevent="currentTab = 'spots'"
                        href="#"
                        class="px-4 py-2 rounded-md font-sans uppercase text-sm"
                        :class="{ 'bg-lo-purple-100 text-white': currentTab === 'spots' }"
                        >Spots</a
                    >
                    <a
                        @click.prevent="currentTab = 'itineraries'"
                        href="#"
                        class="px-4 py-2 rounded-md font-sans uppercase text-sm"
                        :class="{ 'bg-lo-purple-100 text-white': currentTab === 'itineraries' }"
                        >Itineraries</a
                    >
                    <a
                        @click.prevent="currentTab = 'favorites'"
                        href="#"
                        class="px-4 py-2 rounded-md font-sans uppercase text-sm"
                        :class="{ 'bg-lo-purple-100 text-white': currentTab === 'favorites' }"
                        >Favorites</a
                    >
                </div>
            </div>

            <!-- Use v-show or v-if to conditionally display content -->
            <div class="flex justify-center gap-7 p-4 flex-wrap" v-show="currentTab === 'spots'">
                <div class="flex w-full flex-col gap-4" v-if="publishedSpots.length">
                    <div class="font-medium text-xl">Published</div>
                    <list-item v-for="spot in publishedSpots" :key="spot.id" :item="spot" />
                </div>

                <div class="flex w-full flex-col gap-4" v-if="draftedSpots.length">
                    <div class="font-medium text-xl">Drafts</div>
                    <list-item v-for="spot in draftedSpots" :key="spot.id" :item="spot" />
                </div>

                <div class="flex w-full flex-col gap-4" v-if="deletedSpots.length">
                    <div class="font-medium text-xl">Deleted</div>
                    <list-item v-for="spot in deletedSpots" :key="spot.id" :item="spot" :deleted="true" />
                </div>
            </div>
            <!-- <div class="flex justify-center w-full gap-7 p-4 flex-wrap" v-show="currentTab === 'itineraries'">
                <itinerary-tile v-for="itinerary in user.itineraries" :itinerary="itinerary" :key="itinerary.id" />
                <div class="h-[60vh] flex items-center justify-center" v-if="user.itineraries.length === 0">No itineraries</div>
            </div>
            <div class="flex justify-center w-full gap-7 p-4 flex-wrap" v-show="currentTab === 'favorites'">
                <spot-tile v-for="spot in user.favorites.spots" :spot="spot" :key="spot.id" />
                <div class="h-[60vh] flex items-center justify-center" v-if="user.favorites.spots.length === 0">No Favorites</div>
            </div> -->
        </div>
    </div>

    <div class="h-[80vh] flex items-center justify-center">
        <loader v-if="isLoading" />
    </div>
    <main-footer />
</template>

<script>
import ListItem from "@/admin/ListItem.vue";
import store from "@/store";
export default {
    name: "ProfileView",
    components: {
        ListItem,
    },
    mixins: [],
    data() {
        return {
            currentTab: "spots",
            isLoading: true,
            user: {},
        };
    },
    methods: {
        async fetchUserData() {
            this.isLoading = true;
            const token = await this.$auth.getAccessToken();
            let res = await this.$api("me", { token });
            let data = await res.json();

            // TODO: handle error
            if (!res.ok) {
                this.isLoading = false;
                console.log("Error fetching user data", data);
                return;
            }

            this.user = data;
            this.isLoading = false;
            console.log(data);
        },
    },
    computed: {
        id() {
            return this.$store.state.user.username;
        },
        publishedSpots() {
            return this.user.spots.filter((spot) => spot.status === "Publish");
        },
        draftedSpots() {
            return this.user.spots.filter((spot) => spot.status === "Draft");
        },
        deletedSpots() {
            return this.user.spots.filter((spot) => spot.status === "Delete");
        },
    },
    mounted() {
        this.fetchUserData();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.fetchUserData();
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchUserData();
        next();
    },
};
</script>

<style scoped lang="scss"></style>
