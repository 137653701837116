<template>
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="17px"
        height="17px"
        viewBox="0 0 17 17"
        style="enable-background: new 0 0 17 17"
        xml:space="preserve"
    >
        <g>
            <path
                style="fill: #311f28"
                d="M13.45,0.848C9.094,0.16,8.5,4.197,8.5,4.197S7.906,0.16,3.549,0.848
		c-4.354,0.689-4.007,6.745-0.791,9.158c3.001,2.251,5.396,3.898,5.706,6.227h0.069c0.312-2.328,2.708-3.976,5.707-6.227
		C17.459,7.592,17.805,1.537,13.45,0.848z M13.695,9.279l-0.561,0.418c-1.969,1.471-3.654,2.732-4.635,4.197
		c-0.981-1.467-2.667-2.729-4.636-4.199l-0.56-0.416C1.748,8.111,0.886,5.75,1.345,3.904c0.21-0.85,0.787-1.911,2.349-2.159
		C3.978,1.701,4.252,1.68,4.509,1.68c2.669,0,3.073,2.541,3.09,2.65C7.665,4.774,8.048,5.105,8.5,5.105S9.335,4.774,9.401,4.33
		c0.042-0.268,0.494-2.65,3.091-2.65c0.255,0,0.531,0.021,0.816,0.065c1.56,0.248,2.138,1.31,2.347,2.159
		C16.113,5.75,15.252,8.111,13.695,9.279z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "heart",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
