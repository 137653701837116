<template>
    <main-nav />
    <div v-if="isLoading" class="container flex justify-center items-center h-[80vh]">
        <loader />
    </div>
    <div class="container min-h-screen mt-12" v-else>
        <div class="columns-1 md:columns-2 lg:columns-4">
            <itinerary-tile v-for="itinerary in itineraries" :itinerary="itinerary" :key="itinerary.id" />
        </div>
    </div>
    <main-footer />
</template>

<script>
export default {
    name: "HomeView",
    components: {},
    data() {
        return {
            isLoading: true,
            itineraries: [],
        };
    },

    methods: {
        async fetchData() {
            let res = await this.$api("itineraries");
            let data = await res.json();
            this.itineraries = data;
            this.isLoading = false;
        },
    },
    async created() {
        await this.fetchData(this.$route.params.id);
    },
    mounted() {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.fetchData(to.params.id);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.fetchData(to.params.id);
        next();
    },
};
</script>
