<template>
    <div class="relative">
        <div class="flex flex-wrap gap-2">
            <div v-for="(tag, index) in tags" :key="index" class="bg-lo-green-200 flex text-white px-3 text-label uppercase py-2 rounded-full text-sm">
                {{ tag.name }}
                <button class="ml-1 focus:outline-none" @click="removeTag(index)">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </button>
            </div>
        </div>
        <input
            v-model="newTag"
            type="text"
            placeholder="Enter tag"
            @input="showDropdown = !!newTag"
            @keydown.enter.prevent="addTag"
            @focus="showDropdown = !!newTag"
            @blur="showDropdown = false"
            class="w-full mt-2 py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-lo-purple-300 focus:border-lo-purple-300"
        />
        <div v-if="showDropdown && filteredTags.length" class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md">
            <ul>
                <li v-for="(tag, index) in filteredTags" :key="index" @click="selectTag(tag)" class="cursor-pointer py-1 px-3 hover:bg-gray-100">
                    {{ tag.name }}
                </li>
            </ul>
        </div>
        <div class="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md">
            <ul>
                <li v-for="(tag, index) in filteredTags" :key="index" @click="selectTag(tag)" class="cursor-pointer py-2 px-3 hover:bg-gray-100 capitalize">
                    {{ tag.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Fuse from "fuse.js";
// onst options = {
//   includeScore: true,
//   // Search in `author` and in `tags` array
//   keys: ['author', 'tags']
// }

// const fuse = new Fuse(list, options)

// const result = fuse.search('tion')
export default {
    name: "TagPicker",
    props: {
        tags: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            newTag: "",
            apiTags: [],
            showDropdown: false,
        };
    },
    computed: {
        filteredTags() {
            if (this.newTag && this.fuse) {
                const results = this.fuse.search(this.newTag);

                const selectedTagNames = this.tags.map((tag) => tag.name);

                let filtered = results.filter((result) => !selectedTagNames.includes(result.item.name));

                return filtered.map((result) => result.item);
            }
            return [];
        },
    },
    methods: {
        async fetchAllTags() {
            const response = await this.$api("/tags");
            const tags = await response.json();
            this.apiTags = tags;
            this.fuse = new Fuse(tags, {
                keys: ["name"],
                includeScore: true,
                threshold: 0.4,
            });
        },
        addTag() {
            if (this.newTag && !this.tags.includes(this.newTag)) {
                this.tags.push({ name: this.newTag, new: true });
            }
            this.newTag = "";
            this.$emit("selectedTags", this.tags);
        },
        removeTag(index) {
            this.tags.splice(index, 1);
        },
        selectTag(tag) {
            this.newTag = "";
            if (!this.tags.includes(tag)) {
                this.tags.push(tag);
            }
            this.showDropdown = false;

            this.$emit("selectedTags", this.tags);
        },
    },
    unmounted() {},
    mounted() {
        this.fetchAllTags();
    },
};
</script>

<style scoped>
/* Add custom styles here */
</style>
