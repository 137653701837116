<template>
    <div ref="map"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
export default {
    name: "spots-map",
    props: {
        spots: {
            type: Array,
            required: true,
            default: () => [],
        },
        fitToBounds: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        usersLocation: {
            type: Object,
            default: null,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {
            map: null,
        };
    },
    computed: {
        geojson() {
            return {
                type: "FeatureCollection",
                features: this.spots.map((spot) => {
                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: spot.location.coordinates,
                        },
                        properties: {
                            id: spot._id,
                            name: spot.name,
                            slug: spot.slug,
                        },
                    };
                }),
            };
        },
    },
    methods: {
        openSpot(spot) {
            this.$router.push({ path: "/spots/" + spot.slug });
        },
    },
    created() {},
    watch: {
        geojson: {
            handler(newGeoJSON) {
                if (this.map && this.map.getSource("locations")) {
                    this.map.getSource("locations").setData(newGeoJSON);
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.map = new mapboxgl.Map({
            container: this.$refs.map,
            style: process.env.VUE_APP_MAPBOX_STYLE,
            zoom: 10,
            minZoom: 3.0,
        });
        this.map.on("load", () => {
            // Add the GeoJSON as a source
            this.map.addSource("locations", {
                type: "geojson",
                data: this.geojson,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points
            });

            // Add a layer for the clusters
            this.map.addLayer({
                id: "clusters",
                type: "circle",
                source: "locations",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": ["step", ["get", "point_count"], "#311f28", 100, "#311f28", 750, "#311f28"],
                    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
                },
            });

            // Add a layer for the cluster count labels
            this.map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "locations",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
                paint: {
                    "text-color": "#ffffff", // This sets the text color to white
                },
            });
            // Add a layer for the unclustered points
            this.map.addLayer({
                id: "unclustered-point",
                type: "circle",
                source: "locations",
                filter: ["!", ["has", "point_count"]],
                paint: {
                    "circle-color": "#311f28",
                    "circle-radius": 10,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#fff",
                },
            });

            // Inspect a cluster on click
            this.map.on("click", "clusters", (e) => {
                var features = this.map.queryRenderedFeatures(e.point, {
                    layers: ["clusters"],
                });
                var clusterId = features[0].properties.cluster_id;
                this.map.getSource("locations").getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;
                    this.map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom,
                    });
                });
            });

            // When a click occurs on a feature in the unclustered-point layer, display popup with more info
            this.map.on("click", "unclustered-point", (e) => {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var properties = e.features[0].properties;
                // Ensure that if the map is zoomed out such that multiple copies of the feature are visible,
                // the popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Modify popup HTML to include an onclick event
                var popupContent = `<a href="/spots/${properties.slug}">${properties.name}</a>`;

                new mapboxgl.Popup().setLngLat(coordinates).setHTML(popupContent).addTo(this.map);
            });

            // Change the cursor to a pointer when the mouse is over the clusters
            this.map.on("mouseenter", "clusters", () => {
                this.map.getCanvas().style.cursor = "pointer";
            });
            this.map.on("mouseleave", "clusters", () => {
                this.map.getCanvas().style.cursor = "";
            });
            // on scroll get the zoom
            this.map.on("zoom", () => {
                console.log("zoom", this.map.getZoom());
            });

            this.map.on("moveend", () => {
                const bounds = this.map.getBounds();
                this.$emit("boundsUpdated", bounds);
            });
        });
    },
};
</script>

<style scoped lang="scss"></style>
