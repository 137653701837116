<template>
    <main-nav class="w-full fixed top-0"></main-nav>
    <div class="min-h-full messages-wrapper">
        <div class="flex-1 h-full px-4 py-8 messages" ref="messages">
            <div v-if="history.length === 0" class="flex flex-col text-center items-center justify-center h-full w-full">
                <div class="p-4 rounded-lg flex flex-col items-center justify-center">
                    <img src="@/assets/logo-dark.svg" alt="" class="py-6" />
                    <p class="max-w-96">
                        👋 I'm here to help you build a research trip for your project. Ask me about best-in-class retail, amazing cafes, cool interactive exhibits.
                    </p>
                </div>
            </div>
            <div v-for="message in history" :key="message.id" class="mb-4">
                <Message :message="message" />
            </div>
        </div>
    </div>

    <div class="flex-none px-4 py-4 w-full flex items-center justify-center fixed bottom-0 bg-lo-purple-300">
        <div class="relative w-full max-w-[800px]">
            <input
                v-model="inputMessage"
                @keyup.enter="sendMessage"
                placeholder="Type your message..."
                class="w-full py-4 pl-4 pr-10 rounded-lg border border-gray-300 focus:outline-none focus:border-lo-purple-500 focus:ring focus:ring-lo-purple-200 focus:ring-opacity-50 transition duration-200"
            />
            <button
                :disabled="inputMessage.length === 0"
                @click="sendMessage"
                class="disabled:opacity-50 disabled:cursor-not-allowed absolute right-3 top-1/2 transform -translate-y-1/2 text-lo-purple-300 bg-lo-purple-100 p-3 rounded-lg w-10 h-10 flex items-center justify-center"
            >
                <i class="fa fa-paper-plane"></i>
            </button>
        </div>
    </div>
</template>

<script>
import Message from "./Message.vue";
import { nanoid } from "nanoid";
export default {
    components: {
        Message,
    },
    data() {
        return {
            history: [],
            isLoading: false,
            inputMessage: "",
        };
    },
    methods: {
        scrollToBottom() {
            this.$nextTick(() => {
                this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
            });
        },
        sendMessage() {
            if (this.inputMessage.length === 0) return;

            const message = {
                id: nanoid(),
                content: this.inputMessage,
                role: "user",
            };

            this.history.push(message);

            this.inputMessage = "";

            this.scrollToBottom();

            this.sendQueryToBackend(message, this.history);
        },

        async sendQueryToBackend(message, history) {
            const token = await this.$auth.getAccessToken();

            const id = nanoid();
            this.isLoading = true;
            let pendingResponse = {
                id: id,
                content: "",
                role: "assistant",
                isLoading: true,
            };

            this.history.push(pendingResponse);

            let h = this.history.filter((m) => !m.isLoading);

            try {
                let body = JSON.stringify({ message: message, id, history: h });
                console.log(body);
                let res = await this.$api("chat", { method: "POST", token, body });
                let data = await res.json();
                let { response } = data;

                // get the last message based on id

                this.history[this.history.length - 1] = response;

                this.isLoading = false;

                console.log(response);
                // scroll messages to bottom
                this.scrollToBottom();
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
        // fixed body
        document.body.style.overflow = "hidden";
        this.scrollToBottom();
    },
};
</script>

<style lang="scss">
.messages-wrapper {
    height: calc(100vh - 149px);
    margin-top: 59px;
    @apply overflow-hidden;
    .messages {
        overflow-y: auto;
        height: 100%;
    }
}
.app-link {
    display: inline;
    @apply bg-lo-green-200 text-white;
}
</style>
