import MarkdownIt from "markdown-it";
import { computed, defineComponent, h, ref } from "vue";

var VueMarkdown = defineComponent({
    name: "VueMarkdown",
    props: {
        source: { type: String, required: true },
        options: { type: Object, required: false },
        plugins: { type: Array, required: false },
    },
    setup: function (props) {
        var md = ref(new MarkdownIt(props.options ? props.options : {}));

        // Iterate through any plugins and apply them
        (props.plugins || []).forEach((plugin) => {
            md.value.use(plugin);
        });

        // Custom link check and render function
        const originalLinkRenderer =
            md.value.renderer.rules.link_open ||
            function (tokens, idx, options, env, self) {
                return self.renderToken(tokens, idx, options);
            };
        md.value.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            // Check if the link URL is '/spots' and append class if true
            const aIndex = tokens[idx].attrIndex("href");
            if (tokens[idx].attrs[aIndex][1].indexOf("/spots") != -1) {
                const classIndex = tokens[idx].attrIndex("class");
                const newClass = "app-link"; // Replace with your actual class
                if (classIndex < 0) {
                    tokens[idx].attrPush(["class", newClass]);
                } else {
                    // Append the class if it's not already included
                    if (!tokens[idx].attrs[classIndex][1].includes(newClass)) {
                        tokens[idx].attrs[classIndex][1] += " " + newClass;
                    }
                }
            }
            // Use the original renderer for other conditions
            return originalLinkRenderer(tokens, idx, options, env, self);
        };

        // Compute the markdown content
        var content = computed(function () {
            return md.value.render(props.source);
        });

        // Return the rendered HTML content
        return function () {
            return h("div", { innerHTML: content.value });
        };
    },
});

export default VueMarkdown;
