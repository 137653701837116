<template>
    <button class="form-button flex items-center justify-center" :disabled="disabled">
        <span v-if="isLoading" class="absolute">
            <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </span>
        {{ title }}
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Button Title",
        },
    },
    name: "FormButton",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.form-button {
    @apply bg-lo-green-200 hover:bg-lo-green-300 text-white font-bold py-2 px-4 rounded min-w-[100px];
    &:disabled {
        @apply bg-gray-400 cursor-not-allowed text-opacity-50;
    }
}
</style>
