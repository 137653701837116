<template>
    <div class="flex gap-2 flex-wrap">
        <div
            v-for="category in categories"
            :key="category.id"
            @click="handleSelected(category)"
            class="bg-lo-gray-100 p-3 rounded-md cursor-pointer text-label"
            :class="category.selected ? 'bg-lo-green-200 text-white' : 'text-black'"
        >
            {{ category.name }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
            required: true,
        },
    },
    methods: {
        handleSelected(category) {
            category.selected = !category.selected;
        },
    },
    computed: {},
    mounted() {},
};
</script>

<style scoped></style>
