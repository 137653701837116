<template>
    <div ref="map"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
let map = null;
let marker = null;
export default {
    name: "spot-map",
    props: {
        location: {
            type: Array,
            required: true,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    watch: {
        location: {
            handler: (newLocation) => {
                if (newLocation) {
                    if (marker) {
                        marker.setLngLat(newLocation);
                    } else {
                        marker = new mapboxgl.Marker().setLngLat(newLocation).addTo(map);
                    }
                    map.setCenter(newLocation);
                }
            },
            deep: true,
        },
    },
    mounted() {
        map = new mapboxgl.Map({
            container: this.$refs.map,
            style: process.env.VUE_APP_MAPBOX_STYLE,
            zoom: 14,
            center: this.location,
        });

        map.on("load", () => {
            marker = new mapboxgl.Marker().setLngLat(this.location).addTo(map);
            map.on("mouseenter", "clusters", function () {
                map.getCanvas().style.cursor = "pointer";
            });
            map.on("mouseleave", "clusters", function () {
                map.getCanvas().style.cursor = "";
            });
        });
    },
};
</script>

<style lang="scss"></style>
