export const getUserLocation = async () => {
    try {
        // This function returns a promise that resolves with the user's location
        const getPosition = (options) => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));

        // Wait for the user's location
        const position = await getPosition();
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Return the user's location as an object
        return { latitude, longitude };
    } catch (error) {
        // Log the error (optional)
        console.error("Error obtaining user location: ", error);

        // Return the default location (center of the US) if there's an error
        return { latitude: 39.8283, longitude: -98.5795 }; // Center of the US
    }
};
