<template>
    <div class="flex gap-3 mb-11 w-full">
        <a class="spot-button flex">
            <div class="flex">
                <div class="py-3 px-6 flex items-center">Been There</div>
                <div class="text-white bg-lo-green-200 flex items-center justify-center p-3 flex-col">
                    <span>{{ count }}</span>
                    <span class="text-[9px] opacity-70">visitors</span>
                </div>
            </div>
        </a>
        <a class="spot-button px-6 py-3">
            <div class="flex">save</div>
        </a>
    </div>
</template>

<script>
export default {
    name: "spot-cta",
    props: {
        count: {
            type: Number,
            default: 0,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
