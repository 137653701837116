<template>
    <div ref="map" class="w-full h-full"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
export default {
    name: "spot-map",
    props: {
        name: {
            type: String,
            default: "map",
        },
        coordinates: {
            type: Array,
            default: () => [-98.5795, 39.8283],
        },
    },
    data() {
        return {
            marker: null,
            map: null,
        };
    },
    watch: {
        coordinates() {
            console.log("coordinates changed", this.coordinates);
            if (!this.marker) return;
            if (!this.map) return;

            this.marker.setLngLat(this.coordinates);
            this.map.flyTo({
                duration: 2500,
                center: this.coordinates,
            });
        },
    },
    mounted() {
        this.map = new mapboxgl.Map({
            container: this.$refs.map,
            style: process.env.VUE_APP_MAPBOX_STYLE,
            accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
            zoom: 14,
            center: this.coordinates,
        });

        this.map.on("load", () => {
            this.marker = new mapboxgl.Marker()
                .setLngLat(this.coordinates)
                .setPopup(new mapboxgl.Popup().setHTML(`<h1>${this.name}</h1>`))
                .addTo(this.map);
        });

        // this.map.on("load", () => {
        //     this.marker = new mapboxgl.Marker()
        //         .setLngLat(this.coordinates)
        //         .setPopup(new mapboxgl.Popup().setHTML(`<h1>${this.name}</h1>`))
        //         .addTo(map);
        //     // this.map.on("mouseenter", "clusters", () => {
        //     //     this.map.getCanvas().style.cursor = "pointer";
        //     // });
        //     // this.map.on("mouseleave", "clusters", () => {
        //     //     this.map.getCanvas().style.cursor = "";
        //     // });
        // });
    },
};
</script>

<style lang="scss"></style>
