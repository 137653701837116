<template>
    <div
        id="drop_zone"
        class="border-2 border-dashed border-gray-300 rounded-md p-12 text-center cursor-pointer w-full my-9"
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
    >
        <p class="text-gray-700">Drag and drop your images here or click to select images</p>
        <input type="file" multiple class="hidden" @change="handleFiles" id="file_input" accept="image/*" />
        <div class="mt-4">
            <div class="flex flex-wrap justify-start gap-4">
                <div v-for="(url, index) in imageUrls" :key="index" class="relative">
                    <img :src="url" class="max-w-xs max-h-32 rounded-lg" alt="Image preview" />
                    <button class="absolute top-2 right-2 bg-lo-pink-200 text-white rounded-full p-1 w-[30px] h-[30px]" @click.stop="removeImage(index)">&#10005;</button>
                </div>

                <div v-for="(file, index) in files" :key="index" class="relative">
                    <img :src="file.url" class="max-w-xs max-h-32 rounded-lg" alt="Image preview" />
                    <button class="absolute top-2 right-2 bg-lo-pink-200 text-white rounded-full p-1 w-[30px] h-[30px]" @click.stop="deleteImage(index)">&#10005;</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageUploader",
    props: {
        files: {
            type: Array,
            default: () => [],
        },
    },
    components: {},
    mixins: [],
    data() {
        return {
            imageUrls: [], // This will store the image preview URLs
        };
    },
    methods: {
        deleteImage(index) {
            this.$emit("deleteImage", index);
        },
        handleDragOver(event) {
            event.target.classList.add("bg-gray-100"); // Change background when dragging over
        },
        handleDragLeave(event) {
            event.target.classList.remove("bg-gray-100"); // Revert background when leaving
        },
        handleDrop(event) {
            const files = event.dataTransfer.files;
            this.uploadFiles(files);
            event.target.classList.remove("bg-gray-100"); // Revert background after dropping
        },
        handleFiles(event) {
            const files = event.target.files;
            const filteredFiles = Array.from(files).filter((file) => file.type.startsWith("image/")); // Keep only image files
            if (filteredFiles.length !== files.length) {
                alert("Only image files are allowed."); // Inform the user (could be improved with a nicer UI feedback)
            }
            this.uploadFiles(filteredFiles);
        },
        removeImage(index) {
            this.imageUrls.splice(index, 1); // Remove the image URL at the specified index
        },
        uploadFiles(files) {
            this.imageUrls = []; // Clear out any existing URLs
            Array.from(files).forEach((file) => {
                if (file.type.startsWith("image/")) {
                    // Make sure it's an image
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.imageUrls.push(e.target.result); // Add the image URL to our array
                    };
                    reader.readAsDataURL(file); // Read the file as a Data URL (base64)
                }
            });

            this.$emit("imagesSelected", files);
        },

        triggerFileInput() {
            document.getElementById("file_input").click();
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
