<template>
    <div v-for="tag in tags" :key="tag.id" class="bg-gray-200 px-3 py-3 rounded-full text-tiny uppercase hover:bg-lo-green hover:text-black cursor-pointer">
        {{ tag.name }}
    </div>
</template>

<script>
export default {
    name: "Tags",
    props: {
        tags: {
            type: Array,
            required: true,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
