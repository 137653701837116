<template>
    <div :id="`spot-${spot.id}`" class="rounded-lg w-full md:max-w-[350px] bg-white overflow-hidden justify-between flex flex-col">
        <div class="mb-9 relative cursor-pointer" @click="openSpot">
            <swiper :slides-per-view="1" :space-between="0" class="w-full h-56" @slideChange="onSlideChange" :lazy="true">
                <swiper-slide v-for="(photo, index) in spot.photos" :key="index">
                    <img loading="lazy" :src="photo.url" :alt="spot.url" class="w-full h-full object-cover object-center bg-gray-400" />
                    <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </swiper-slide>
            </swiper>
            <div v-if="spot.photos.length > 1" class="text-tiny absolute bottom-2 z-10 text-white right-2">{{ photoIndex }}/{{ spot.photos.length }}</div>
        </div>

        <div class="p-4 h-full">
            <router-link :to="`/spots/${spot.slug}`" class="font-sans font-bold uppercase text-xl link">{{ spot.name }}</router-link>
            <p class="text-gray-600 text-tiny mb-4">{{ spot.address || "" }}</p>
            <p class="text-gray-700 mb-4 description">{{ spot.description }}</p>
            <slot></slot>

            <div class="flex flex-wrap gap-2 my-6">
                <span
                    v-for="tag in spot.tags"
                    :key="tag.id"
                    class="bg-lo-gray-100 text-lo-purple-200 hover:bg-lo-green cursor-pointer px-3 py-2 uppercase rounded-full text-tiny"
                    >{{ tag.name }}</span
                >
            </div>
        </div>

        <div class="p-4">
            <div class="flex flex-wrap gap-1">
                <div v-for="visit in spot.visits" :key="visit.id" class="bg-gray-200 px-1 py-1 rounded-full text-xs">
                    <user-avatar :user="visit.user" :showName="false" />
                </div>
            </div>
        </div>

        <div class="footer bg-lo-purple-200" v-if="spot.user">
            <div class="flex items-start p-4 flex-col">
                <div class="flex items-center">
                    <router-link :to="`/users/${spot.user.username}`">
                        <img
                            :src="spot.user.photo ? spot.user.photo.url : '/assets/missing.svg'"
                            alt="item photo"
                            class="w-6 h-6 mr-2 object-cover object-center bg-gray-400 rounded-full"
                        />
                    </router-link>
                    <div>
                        <div class="text-tiny text-white">{{ spot.user.firstname }} {{ spot.user.lastname }}</div>
                        <div class="text-tiny text-gray-300">
                            {{
                                new Date(spot.created_at).toLocaleString("en-US", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
export default {
    name: "spot-tile",
    props: {
        spot: {
            type: Object,
            required: true,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {
            photoIndex: 1,
        };
    },
    computed: {
        apiUrl() {
            return store.state.apiUrl;
        },
    },
    methods: {
        onSlideChange(swiper) {
            this.photoIndex = swiper.activeIndex + 1;
        },
        openSpot() {
            this.$router.push(`/spots/${this.spot.slug}`);
        },
    },
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
