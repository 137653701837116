<template>
    <template v-if="isLoading">
        <loader />
        loading...
    </template>
    <template v-else>
        <slot />
    </template>
</template>

<script>
export default {
    name: "PageLoader",
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
