<template>
    <footer class="h-44 bg-lo-purple-300 w-full flex justify-center mt-40 z-20 relative">
        <div class="h-full w-full">
            <div class="container flex flex-col md:items-start gap-3 justify-center w-full h-full">
                <img src="@/assets/logo.svg" width="100px" class="mb-4" />
                <ul class="flex flex-row gap-3 text-white text-xs">
                    <li><a href="#link">About</a></li>
                    <li><a href="#link">FAQ</a></li>
                    <li><a href="#link">Help</a></li>
                    <li><a href="#link">IDEO</a></li>
                </ul>
                <div class="text-white text-xs">For help or questions e-mail <a href="#email-us">localsonly@ideo.com</a></div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "main-footer",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
