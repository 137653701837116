<template>
    <div class="rounded-lg w-full md:max-w-96 overflow-hidden flex flex-col bg-lo-gray-100 mb-6" v-if="itinerary">
        <div class="h-full flex flex-col">
            <div class="font-medium text-xl bg-lo-purple-100 text-white relative h-[300px]">
                <router-link :to="`/itineraries/${itinerary._id}`">
                    <img :src="thumbnail" alt="itinerary thumbnail" class="absolute object-cover w-full h-full overflow-hidden" />
                </router-link>
                <div class="absolute top-9 left-0 px-6 py-4">
                    <router-link class="link uppercase font-thin z-10 bg-lo-green" :to="`/itineraries/${itinerary._id}`">{{ itinerary.title }}</router-link>
                    <div class="text-sm">{{ itinerary.spots.length ? itinerary.spots.length + " Spots" : "No Spots" }}</div>
                </div>
            </div>

            <div class="p-4 h-full">
                <description class="mb-4" color="bg-lo-gray-200" :description="itinerary.description" />
                <div class="flex flex-wrap gap-2 my-6">
                    <tags :tags="itinerary.categories" />
                </div>
            </div>

            <div class="footer bg-lo-gray-200">
                <div class="flex items-start p-4 flex-col">
                    <div class="flex items-center">
                        <router-link :to="`/users/${itinerary.user.username}`">
                            <img v-if="itinerary.user.photo" :src="itinerary.user.photo.url" alt="user avatar" class="w-7 h-7 rounded-full mr-2" />
                        </router-link>
                        <div>
                            <div class="text-tiny text-white">{{ itinerary.user.firstname }} {{ itinerary.user.lastname }}</div>
                            <div class="text-tiny text-white">
                                {{
                                    new Date(itinerary.created_at).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    })
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "itenerary-tile",
    props: {
        itinerary: {
            type: Object,
            required: true,
        },
    },
    computed: {
        thumbnail() {
            return this.itinerary.thumbnail ? this.itinerary.thumbnail.url : "/assets/no-photo.jpg";
        },
    },
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
