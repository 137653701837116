<template>
    <router-view />
    <div v-if="!didClickOK" class="bg-lo-purple-100 bg-opacity-90 text-white p-4 top-0 left-0 z-50 w-full h-screen flex items-center justify-center backdrop-blur-lg fixed">
        <div class="max-w-[600px] text-center">
            <h1 class="text-3xl font-thin text-center font-serif leading-10">
                This site has been resurrected from the cobwebs of the internet, brought back from its 2014 version. Currently, the data is static and not optimized, but we hope to
                fully revitalize it soon.
            </h1>
            <button @click="clickConsent" class="bg-lo-green px-9 py-6 mt-9">OK!</button>
        </div>
    </div>
</template>

<script>
function setCookie(name, value) {
    let expires = "";
    const date = new Date();
    // Set it to expire in 10 years
    date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export default {
    name: "App",
    components: {},
    mixins: [],
    data() {
        return {
            didClickOK: false,
        };
    },
    methods: {
        clickConsent() {
            setCookie("did_click_2014_ok_to_enter", true);
            this.didClickOK = true;
        },
    },
    created() {
        this.didClickOK = getCookie("did_click_2014_ok_to_enter");
    },
    mounted() {
        console.log(this.$auth);
    },
};
</script>

<style scoped lang="scss">
@import "./main.scss";
</style>
