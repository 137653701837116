import BeenThere from "./BeenThere";
import Heart from "./Heart";
import Pencil from "./Pencil";

const svgs = {
    BeenThere,
    Pencil,
    Heart,
};

export default {
    install: (app) => {
        for (const svg in svgs) {
            app.component(`svg-${svgs[svg].name.toLowerCase()}`, svgs[svg]);
        }
    },
};
