<template>
    <div v-if="hasSpot">
        <!-- photos and map -->
        <div class="relative flex flex-wrap flex-col h-[50vh] max-h-[600px] lg:max-h-[800px]">
            <div class="bg-lo-green text-white z-10 w-full inline-block absolute top-10 clip-slant-l backdrop-blur-lg bg-opacity-90 md:max-w-full">
                <div class="container">
                    <div class="flex flex-col py-4 md:max-w-[50%]">
                        <h1 class="text-3xl font-sans font-thin uppercase mb-1">{{ name }}</h1>
                        <div class="uppercase text-xs font-sans font-thin break-words">{{ address }}</div>
                    </div>
                </div>
            </div>

            <div class="absolute bg-lo-purple-100 h-full overflow-hidden w-full md:w-3/4">
                <swiper
                    :slides-per-view="1"
                    @swiper="initSwiper"
                    :space-between="0"
                    class="h-full w-full"
                    @slideChange="onSlideChange"
                    :pagination="{ clickable: true }"
                    :lazy="true"
                >
                    <swiper-slide v-for="(photo, index) in photos" :key="index">
                        <img loading="lazy" :src="photo.url" :alt="spot.url" class="w-full h-full object-cover object-center bg-gray-400" />
                        <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </swiper-slide>
                </swiper>
                <div class="absolute bottom-5 left-5 z-20 flex gap-3" v-if="photos.length > 1">
                    <div
                        v-for="(p, index) in photos"
                        @click="swiper.slideTo(index)"
                        :key="`dot-${p.id}`"
                        :class="`hover:bg-lo-light-purple cursor-pointer w-3 h-3 rounded-full ${index == photoIndex ? `bg-lo-purple-100` : 'bg-white'}`"
                    ></div>
                </div>
            </div>

            <div class="clip-slant-r h-full w-full md:w-[50%] hidden bg-lo-tan md:absolute right-0 z-10 md:flex flex-col items-center justify-center">
                <spot-map :name="spot.name" :coordinates="coordinates" class="spot-map py-11" />
                <spot-cta :count="visits.length" class="justify-center my-6" />
            </div>
        </div>

        <!-- description -->
        <div class="bg-lo-blue-100 py-8 md:py-32">
            <div class="container">
                <div class="flex flex-wrap -mx-4">
                    <div class="md:hidden px-4">
                        <spot-cta :count="visits.length" />
                    </div>

                    <div class="w-full md:w-7/12 px-4">
                        <h2 class="text-3xl text-lo-gray-200 font-serif italic mb-6 leading-10">
                            It’s an
                            <router-link class="text-lo-purple-400 font-bold border-b-2 border-lo-purple-400 link" :to="`#{{ spot.type }}`">{{
                                spot.type || "No Type"
                            }}</router-link>
                            but really it’s...
                        </h2>
                        <div class="font-serif text-2xl first-letter:capitalize text-purple leading-10 spot-description" v-html="description" />

                        <div class="mt-9">
                            <div class="flex mb-1">
                                <h4>Created by:</h4>
                                <router-link :to="`/users/${user.username}`" class="ml-4 h4 link">{{ user.firstname }} {{ user.lastname }}</router-link>
                            </div>
                            <div class="flex">
                                <h4>Spotted on:</h4>
                                <h4 class="ml-4">{{ $formatDate(spot.created_at) }}</h4>
                            </div>
                        </div>
                        <!-- vistors -->
                        <div class="mt-9">
                            <!-- TODO: fix for no visitors -->
                            <h4>{{ visits.length > 0 ? "Visited by:" : "No Visitor" }}</h4>
                            <div class="flex flex-wrap gap-1 mt-2" v-if="visits.length > 0">
                                <div v-for="visit in visits" :key="visit.id" class="bg-gray-200 px-1 py-1 rounded-full text-xs">
                                    <user-avatar :user="visit.user" :showName="false" />
                                </div>
                            </div>
                        </div>

                        <!-- tags -->
                        <div class="mt-9" v-if="tags.length > 0">
                            <h4>Tags:</h4>
                            <div class="flex flex-wrap gap-3 mt-2">
                                <tags :tags="tags" />
                            </div>
                        </div>
                    </div>

                    <div class="px-4 w-full md:w-5/12">
                        <div class="mt-9 md:mt-0 flex flex-wrap gap-4">
                            <categories :categories="spot.categories || []" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- edit button -->
        <router-link
            v-if="canEdit"
            :to="`/spots/${spot._id}/edit`"
            class="fixed bottom-5 right-5 bg-lo-green-200 text-white rounded-full spot-button text-tiny w-9 h-9 p-6 flex items-center justify-center hover:bg-lo-purple-200"
        >
            Edit
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SpotView",
    components: {},
    mixins: [],
    props: {
        showEdit: {
            type: Boolean,
            default: false,
        },
        spot: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            photoIndex: 0,
            swiper: null,
        };
    },
    methods: {
        initSwiper(e) {
            this.swiper = e;
        },
        onSlideChange(swiper) {
            this.photoIndex = swiper.activeIndex;
        },
    },
    computed: {
        name() {
            return this.spot.name || "No Name";
        },
        description() {
            return this.spot.description || "No Description";
        },
        address() {
            return this.spot.address || "No Address";
        },
        user() {
            return (this.spot && this.spot.user) || {};
        },
        canEdit() {
            return this.spot && this.user._id == this.$userID && this.showEdit;
        },
        photos() {
            return (
                this.spot.photos || [
                    {
                        url: "/assets/no-photo.jpg",
                    },
                ]
            );
        },
        hasSpot() {
            return Object.keys(this.spot).length >= 0;
        },
        coordinates() {
            return (this.spot && this.spot.location && this.spot.location.coordinates) || [-122.4194, 37.7749];
        },
        visits() {
            return this.spot.visits || [];
        },
        tags() {
            return this.spot.tags || [];
        },
        user() {
            return (
                this.spot.user || {
                    firstname: "No",
                    lastname: "User",
                }
            );
        },
    },
};
</script>

<style lang="scss">
.clip-slant-r {
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%);
}
.clip-slant-l {
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    @media (max-width: 1200px) {
        clip-path: none;
    }
}
.spot-button {
    @apply transition-all bg-transparent text-lo-green-200 border-4 items-center flex uppercase border-lo-green-200 cursor-pointer hover:bg-lo-green-200 hover:text-white;
}
.spot-description {
    a {
        @apply text-lo-green-200 border-b-lo-green-200 border-b-2 hover:border-b-lo-purple-200;
    }
}
.spot-map {
    background: red;
    height: 300px !important;
    width: 300px !important;
    border-radius: 100%;
}
</style>
