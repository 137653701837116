const apiURL = (endpoint) => {
    return `${process.env.VUE_APP_API_URL}/${endpoint}`;
};
const api = async (path, params) => {
    const { method = "GET", body = null, token, headers = {} } = params || {};

    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    const url = apiURL(path);

    console.log("API", url);
    return fetch(url, {
        method,
        headers: {
            ...headers,
            "Content-Type": "application/json",
        },
        body: body ? body : null,
    });
};

const installAPI = {
    install: (app) => {
        app.config.globalProperties.$apiURL = apiURL;
        app.config.globalProperties.$api = api;
    },
};

export default installAPI;

export { apiURL, api, installAPI };
