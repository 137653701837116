<template>
    <router-link class="flex items-center" :to="path ? path : `/users/${user.username}`">
        <img
            :src="user.photo && user.photo.url ? user.photo.url : '/assets/missing.svg'"
            alt="item photo"
            :class="`${size} ${size} object-cover object-center bg-lo-purple-400 rounded-full`"
        />
        <div v-if="showName" class="text-xs">{{ user.firstname }} {{ user.lastname }}</div>
    </router-link>
</template>

<script>
export default {
    name: "user-avatar",
    props: {
        user: {
            type: Object,
            required: true,
        },
        showName: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "w-6",
        },
        path: {
            type: String,
        },
    },
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    computed: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
