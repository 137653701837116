<template>
    <div class="loader"></div>
</template>

<script>
export default {
    name: "Loader",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
