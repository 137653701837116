<!-- Message.vue -->
<template>
    <div class="" :class="{ 'text-right': message.role === 'user', 'text-left': message.role === 'assistant' }">
        <div v-if="message.role === 'user'" class="bg-lo-purple-200 text-white py-2 px-4 rounded-lg inline-block message">
            <markdown :source="message.content" />
        </div>
        <div v-else class="flex flex-col">
            <div class="bg-lo-blue-100 text-lo-purple-300 py-2 px-4 rounded-lg inline-block message">
                <markdown :source="message.content" />
                <div v-if="message.isLoading">...</div>
            </div>
            <div class="bg-lo-blue-100 text-lo-purple-300 p-4 rounded-lg inline-block message mt-2" v-if="spots.length > 0">
                <!-- <div class="columns-1 md:columns-3 lg:columns-3 xl:columns-4"> -->
                <div class="flex flex-wrap gap-4">
                    <div v-for="spot in spots" :key="spot._id" class="min-w-[150px] mb-4 block">
                        <img :src="spot.thumbnail.url" alt="" class="w-[150px] h-[100px] object-cover rounded-t-lg overflow-hidden" />
                        <div class="bg-lo-purple-200 w-[150px]">
                            <div class="px-3 py-2">
                                <router-link class="text-sm text-white" :to="spot.slug">{{ spot.name }}</router-link>
                                <div class="text-tiny text-gray-300">{{ spot.address }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    components: {},
    computed: {
        spots() {
            return this.message.spots || [];
        },
    },
};
</script>

<style>
.message {
    a {
        @apply underline;
    }
    ol {
        @apply list-decimal mr-4 flex flex-col p-4;
        li {
            @apply mb-3;
        }
    }
    @apply max-w-[60%];
}
</style>
