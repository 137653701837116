<template>
    <main-nav />

    <div class="flex flex-wrap">
        <div class="w-full h-96 lg:w-6/12 lg:h-screen">
            <img src="@/assets/about-bg.jpg" class="object-cover h-full w-full" alt="about" />
        </div>
        <div class="mt-6 lg:mt-0 lg:px-6 description flex justify-center flex-col items-center w-full lg:w-6/12 lg:h-screen">
            <div class="md:max-w-[70%] text-xl md:text-2xl md:leading-10 p-6">
                <p>
                    Traveling for inspiration is one of the greatest parts of what we do at IDEO. You might
                    <span class="highlight">say inspiration finding is an art form at which we excel:</span> We connect to places and people, and draw threads between them, and
                    introduce connections between analogs that otherwise might go unseen.
                </p>
                <p>
                    This is Locals Only’s point-of-view (POV) on <span class="highlight">the types of inspiration we are searching for right now.</span> We want to build our common
                    vocabulary about the inspiration we are finding most; these 9 themes are <span class="highlight">a starting point</span> for us. Maybe you’ll use them to help
                    focus your field work efforts, or they might serve to inspire your client team by showing them what we see at the edges of their industry. We want this to be a
                    starting point that pushes us to ask for more.
                </p>
                <p>The mission of Locals Only is <span class="highlight">to empower each other to travel curiously</span> in search of inspiration. Welcome.</p>
            </div>
        </div>
    </div>
    <main-footer />
</template>

<script>
export default {
    name: "About",
    components: {},
    mixins: [],
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
};
</script>

<style scoped lang="scss"></style>
